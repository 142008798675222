import React, { Component } from 'react';
import { albums_data, people_data, tags_data } from "../_gallery/site_data.js"
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link'
import "./Collections.css";

import withRouter from './withRouter';

class Collections extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    generateCards = (collectionType, subjects) => {
        return (
            <div className="columns is-multiline">
                {Object.keys(subjects).map(subject => { return this.generateCard2(collectionType, subjects[subject]) })}
            </div>
        )
    }

    // generateCard = (collectionType, subject) => {
    //     return (
    //         <Link key={subject.slug} className="column is-one-quarter" to={"/collections/" + collectionType + "/" + subject.slug}>
    //             <div className="card">
    //                 <div className="card-image">
    //                     <figure className="image is-4by3 subject-photo">
    //                         <img className="subject-photo" src={subject.src} alt={subject.name} />
    //                     </figure>
    //                 </div>
    //                 <div className="card-content">
    //                     <div className="media-content">
    //                         <p className="title is-5">{subject.name}</p>
    //                         {/* <p className="subtitle is-7">{subject["photos"].length} Photo{subject["photos"].length === 1 ? '' : 's'}</p> */}
    //                         {/* <p>222{subject.readme}74444</p> */}
    //                         <div className="readme-content" dangerouslySetInnerHTML={{ __html: subject.readme }}></div>
    //                         {process.env.NODE_ENV === 'development' ?  <a href={subject.readme_url}>✎</a> : <div/> }
    //                     </div>
    //                 </div>
    //             </div>
    //         </Link>
    //     );
    // }

    generateCard2 = (collectionType, subject) => {
        return (
            <Link key={subject.slug} className="column is-half" to={"/collections/" + collectionType + "/" + subject.slug}>
                <div className="card">
                    <div className="card-image">
                        {/* <figure className="image is-4by3 subject-photo"> */}
                        <img className="subject-photo" src={subject.src} alt={subject.name} />
                        {/* </figure> */}
                    </div>
                    <div className="card-content">
                        <div className="media-content">
                            <p className="title is-5">{subject.name}</p>
                            {/* <p className="subtitle is-7">{subject["photos"].length} Photo{subject["photos"].length === 1 ? '' : 's'}</p> */}
                            {/* <p>222{subject.readme}74444</p> */}
                            <div className="readme-content" dangerouslySetInnerHTML={{ __html: subject.abstract }}></div>
                            {process.env.NODE_ENV === 'development' ?  <a href={subject.readme_url}>✎</a> : <span/>
                            }
                        </div>
                    </div>
                </div>
            </Link>
        );
    }


    title = (collectionType) => {
        var titleStr = "Unknown"
        if (collectionType == "albums") {
            titleStr = "Моменты"
        }
        else if (collectionType == "tags") {
            titleStr = "Темы"
        }
        else if (collectionType == "people") {
            titleStr = "People"
        }
        return titleStr
    }

    collections = (collectionType) => {
        if (collectionType == "albums") {
            return albums_data
        }
        else if (collectionType == "people") {
            return people_data
        }
        else if (collectionType == "tags") {
            return tags_data
        }
        return null
    }

    render() {

        // If this is the index we dont get this info from the route
        // so if we're missing the param we pull it from props which
        // will have been passed in manually
        if (!("collectionType" in this.props.params)) {
            if (this.props.collectionType != null) {
                this.props.params.collectionType = this.props.collectionType
            }
        }
        return (
            <div className="container">
                <section className="hero is-small">
                    <div className="hero-body">
                        <nav className="breadcrumb" aria-label="breadcrumbs">
                            <ul>
                                <li key="1" className="is-active">
                                    <i className="fas fa-book fa-lg"></i>
                                    <a className="title is-5">&nbsp;&nbsp;{this.title(this.props.params.collectionType)}</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </section>
                <div class="card-content">
                    <div class="content">
                        <div className="readme-content" dangerouslySetInnerHTML={{ __html: this.props.readMe }}></div>    
                        {process.env.NODE_ENV === 'development' ?  <a href={this.props.readmeUrl}>✎</a> : <span/>}
                        {/* <div className="sidewrap"> */}
                        <div>
                            <HashLink to={"/#bottom"}>…↓↓↓ листать в конец ↓↓↓ …</HashLink>
                        </div>
                    </div>
                </div>
                {this.generateCards(this.props.params.collectionType, this.collections(this.props.params.collectionType))}
                <h3 id="bottom">…</h3>                
            </div>
        );
    }
}

export default withRouter(Collections)