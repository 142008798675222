import React, { Component } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import withRouter from './withRouter';
import { albums_data, people_data, tags_data } from "../_gallery/site_data.js"
import SwiperCore, { Keyboard, Pagination, HashNavigation, Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import Modal from 'react-modal';

import { Link } from "react-router-dom";
import "./Collection.css";
// import Videojs from './VideoJS';
// import VideoPlayer from 'react-video-js-player';
import ReactPlayer from 'react-player/youtube'

// import Videojs from './video.js';

const videoJsOptions = {
    autoplay: false,
    playbackRates: [0.5, 1, 1.25, 1.5, 2],
    width: 720,
    height: 300,
    controls: true,
    sources: [
        {
            src: '//vjs.zencdn.net/v/oceans.mp4',
            type: 'video/mp4',
        },
    ],
};



SwiperCore.use([Navigation]); // Not sure why but need this for slide navigation buttons to be clickable
Modal.setAppElement('#app');


class Collection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            viewerIsOpen: true ? this.props.params.image != undefined : false
        };
    }

    modalStateTracker = (event) => {
        var newPath = event.newURL.split("#", 2)
        if (newPath.length < 2) {
            return
        }
        var oldPath = event.oldURL.split("#", 2)
        if (oldPath.length < 2) {
            return
        }

        var closedModalUrl = "/collections/" + this.props.params.collectionType + "/" + this.props.params.collection

        if (this.state.viewerIsOpen) {
            if (
                oldPath[1] != closedModalUrl &&
                newPath[1] == closedModalUrl
            ) {
                this.setState({
                    viewerIsOpen: false
                })
                // var page = document.getElementsByTagName('body')[0];
                // page.classList.remove('noscroll');
            }
        }

        if (!this.state.viewerIsOpen) {
            if (
                oldPath[1] == closedModalUrl &&
                newPath[1] != closedModalUrl
            ) {
                this.setState({
                    viewerIsOpen: true
                })
                // this.props.navigate("/collections/" + this.props.params.collectionType + "/" + this.props.params.collection + "/" + event.target.attributes.slug.value);
                // var page = document.getElementsByTagName('body')[0];
                // page.classList.add('noscroll');
            }
        }
    }

    openModal = (event) => {

        this.props.navigate("/collections/" + this.props.params.collectionType + "/" + this.props.params.collection + "/" + event.target.attributes.slug.value);
        this.setState({
            viewerIsOpen: true
        })
        // Add listener to detect if the back button was pressed and the modal should be closed
        window.addEventListener('hashchange', this.modalStateTracker, false);
        // var page = document.getElementsByTagName('body')[0];
        // page.classList.add('noscroll');
    };

    closeModal = () => {

        this.props.navigate("/collections/" + this.props.params.collectionType + "/" + this.props.params.collection);
        this.setState({
            viewerIsOpen: false
        })
        // var page = document.getElementsByTagName('body')[0];
        // page.classList.remove('noscroll');
    };

    title = (collectionType) => {
        var titleStr = "??"
        if (collectionType == "albums") {
            titleStr = "Моменты"
        }
        if (collectionType == "tags") {
            titleStr = "Темы"
        }
        else if (collectionType == "people") {
            titleStr = "People"
        }
        return titleStr
    }

    collection = (collectionType, collection) => {
        let data = {}
        if (collectionType == "albums") {
            data = albums_data
        }
        else if (collectionType == "people") {
            data = people_data
        }
        else if (collectionType == "tags") {
            data = tags_data
        }
        if (collection in data) {
            return data[collection]
        }
        return {}
    }

    render() {
        let collection_data = this.collection(this.props.params.collectionType, this.props.params.collection)
        return (
            <div className="container" >
                <section className="hero is-small">
                    <div className="hero-body">
                        <nav className="breadcrumb" aria-label="breadcrumbs">
                            <ul>
                                <li>
                                    <i className="fas fa-book fa-lg"></i>
                                    <Link className="title is-5" to={"/collections/" + this.props.params.collectionType}>&nbsp;&nbsp;{this.title(this.props.params.collectionType)}</Link>
                                </li>
                                <li className="is-active">
                                    <a className="title is-5">{collection_data["name"]}</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </section>
                <div className="readme-content" dangerouslySetInnerHTML={{ __html: collection_data.readme }}></div>
                {process.env.NODE_ENV === 'development' ?  <a href={collection_data.readme_url}>✎</a> : <span/> }
                <hr/>
                <ResponsiveMasonry
                    columnsCountBreakPoints={{ 300: 1, 600: 2, 900: 3, 1200: 4, 1500: 5 }}
                >
                    <Masonry
                        gutter="10px"
                    >
                        {collection_data["photos"].map((image, i) => (
                            <img
                                className="gallery-image"
                                key={i}
                                src={image.srcSet["(500, 500)w"]}
                                alt={image.name}
                                slug={image.slug}
                                loading="lazy"
                                onClick={this.openModal}
                            />
                        ))}
                    </Masonry>
                </ResponsiveMasonry>
                <Modal
                    isOpen={this.state.viewerIsOpen}
                    onRequestClose={this.closeModal}
                    preventScroll={true}

                    style={{
                        overlay: {
                            backgroundColor: 'rgba(0, 0, 0, 0.3)'
                        },
                        content: {
                            inset: '10px',
                            padding: '10px',
                            backgroundColor: 'rgba(0, 0, 0, 1)',
                        }
                    }}
                >
                    <button className="button is-text modal-close-button" onClick={this.closeModal} >
                        <span className="icon is-small">
                            <i className="fas fa-times"></i>
                        </span>
                    </button>
                    <Swiper
                        slidesPerView={1}
                        preloadImages={false}
                        navigation={{
                            enabled: true,
                        }}
                        keyboard={{ enabled: true, }}
                        pagination={{ clickable: true, }}
                        hashNavigation={{
                            watchState: true,
                        }}
                        modules={[Keyboard, HashNavigation, 
                                // Pagination
                            ]}
                        className="swiper"
                    >
                        {
                            collection_data["photos"].map(x =>
                                <SwiperSlide slug={x.slug} data-hash={"/collections/" + this.props.params.collectionType + "/" + this.props.params.collection + "/" + x.slug}>
                                    {x.orig.endsWith('.mp4') ?
                                            <video controls
                                                // src='/static/_gallery/albums/za-stsenoi/original_2023-04-13-01-dressirovka-kovrikom-nachalo-01-mp4.mp4'
                                                src={x.orig_src}
                                                poster={x.thumb}
                                                width="100%">
                                                Sorry, your browser doesn't support embedded videos...
                                            </video>
                                        :
                                        <img title={x.name} src={x.src} />
                                    }
                                </SwiperSlide>
                            )
                        }
                    </Swiper>
                </Modal>
            </div>
        );
    }
}

export default withRouter(Collection)